const baseUrl = 'https://saved.bobbygeorge.dev';

declare global {
  interface Window {
    Clerk: any;
  }
}

// Helper function for logging
function log(message: string, data?: any) {
  console.log(`[API] ${message}`, data ? data : '');
}

// Helper function to create headers with Clerk JWT
async function createHeaders() {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');
  
  // Get the Clerk token
  const clerkToken = await window.Clerk.session?.getToken({
    template: "supabase",
  });
  headers.append('Authorization', `Bearer ${clerkToken}`);
  return headers;
}

export async function createCheckoutSession() {
  const headers = await createHeaders();
  log('Creating checkout session');

  const response = await fetch(`${baseUrl}/create-checkout-session`, {
    method: 'POST',
    headers: headers,
  });

  if (!response.ok) {
    const error = `Request failed with status ${response.status}`;
    log('Checkout session creation error', error);
    throw new Error(error);
  }

  const data = await response.json();
  return data.sessionId;
}

export async function createCancellationSession() {
  const headers = await createHeaders();
  console.log('Creating cancellation session');

  try {
    const response = await fetch(`${baseUrl}/create-cancellation-session`, {
      method: 'POST',
      headers: headers,
    });

    console.log('Response status:', response.status);

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const data = await response.json();
    console.log('Received data:', data);

    if (typeof data === 'string' && data.startsWith('http')) {
      return data;
    } else if (data && data.url) {
      return data.url;
    } else {
      throw new Error('Invalid response format');
    }
  } catch (error) {
    console.error('Error in createCancellationSession:', error);
    throw error;
  }
}


export async function processFrame(url: string, video_type: string) {
  const headers = await createHeaders();
  log('Processing frame', { url, video_type });

  const response = await fetch(`${baseUrl}/process-frame/`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      clip_link: url,
      video_type: video_type
    }),
  });

  if (!response.ok) {
    const error = `Request failed with status ${response.status}`;
    log('Frame processing error', error);
    throw new Error(error);
  }

  const data = await response.json();
  const jobId = data.job_id;

  let jobData = null;
  while (jobData == null || jobData.status == 'processing') {
    const statusResponse = await fetch(`${baseUrl}/check-status/${jobId}`, {
      headers: headers
    });
    jobData = await statusResponse.json();

    if (!jobData.result?.frame) {
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }

  return jobData;
}

export async function frameFromVod(clip_link: string, start_time: string) {
  const headers = await createHeaders();
  log('Processing frame from VOD', { clip_link, start_time });

  const response = await fetch(`${baseUrl}/frame-from-vod/`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      clip_link,
      start_time
    }),
  });

  if (!response.ok) {
    const error = `Request failed with status ${response.status}`;
    log('Frame from VOD processing error', error);
    throw new Error(error);
  }

  const data = await response.json();
  log('Frame from VOD processing initiated', { message: data.message, jobId: data.job_id });
  
  // Poll for job completion
  let jobData = null;
  while (!jobData || jobData.status === 'processing') {
    jobData = await checkStatus(data.job_id);
    if (jobData.status !== 'completed') {
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }

  return jobData;  // Return jobData.result instead of jobData
}


export async function processClip(
  url: string,
  subtitles: string,
  fullscreen: boolean,
  text_color: string,
  censor_subtitles: boolean,
  facecam_coordinates: [number | null, number | null, number | null, number | null],
  gameplay_bounds: [number | null, number | null, number | null, number | null],
  font_name: string
) {
  const headers = await createHeaders();
  const requestData = { clip_link: url, subtitles, fullscreen, text_color, censor_subtitles, facecam_coordinates, gameplay_bounds };
  log('Processing clip', requestData);

  const response = await fetch(`${baseUrl}/process-clip/`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(requestData),
  });

  if (!response.ok) {
    const error = `Request failed with status ${response.status}`;
    log('Clip processing error', error);
    throw new Error(error);
  }

  const data = await response.json();
  log('Clip processing initiated', { jobId: data.job_id });
  return data;
}

export const findClipsFromStreamId = async (requestBody: {
  stream_id: string;
  subtitles: string | null;
  fullscreen: boolean;
  text_color: string;
  censor_subtitles: boolean;
  facecam_coordinates: [number, number, number, number] | [] | null;
  gameplay_bounds: [number, number, number, number] | [] | null; 
  font_name: string | null;
}) => {
  const headers = await createHeaders();

  // Check if gameplay_bounds is an empty array, if so, set it to null
  if (Array.isArray(requestBody.gameplay_bounds) && requestBody.gameplay_bounds.length === 0) {
    requestBody.gameplay_bounds = null;
  }
  if (Array.isArray(requestBody.facecam_coordinates) && requestBody.facecam_coordinates.length === 0) {
    requestBody.facecam_coordinates = null;
  }

  const response = await fetch(`${baseUrl}/find_clips_from_stream_id`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
};



export async function findHighlightsSer(url: string, num_of_highlights: number) {
  const headers = await createHeaders();
  const requestData = { url, num_of_highlights };
  log('Finding highlights', requestData);

  const response = await fetch(`${baseUrl}/find_highlights_ser`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(requestData),
  });

  if (!response.ok) {
    const error = `Request failed with status ${response.status}`;
    log('Find highlights error', error);
    throw new Error(error);
  }

  const data = await response.json();
  log('Highlights found', { jobId: data.job_id });
  return data;
}

export async function checkStatus(jobId: string) {
  const headers = await createHeaders();
  // log('Checking job status', { jobId });

  const response = await fetch(`${baseUrl}/check-status/${jobId}`, {
    method: 'GET',
    headers: headers,
  });

  if (!response.ok) {
    const error = `Request failed with status ${response.status}`;
    log('Check status error', error);
    throw new Error(error);
  }

  const data = await response.json();
  log('Job status', { jobId, status: data.status });
  return data;
}

export async function getInfoFromHighlightId(highlightId: string) {
  const headers = await createHeaders();
  log('Getting info from highlight ID', { highlightId });

  const response = await fetch(`${baseUrl}/info_from_highlight_id/${highlightId}`, {
    method: 'GET',
    headers: headers,
  });

  if (!response.ok) {
    const error = `Request failed with status ${response.status}`;
    log('Get info from highlight ID error', error);
    throw new Error(error);
  }

  const data = await response.json();
  log('Info from highlight ID received', { highlightId, data });
  return data;
}

export interface StreamInfo {
  stream_id: string;
  stream_url: string;
  highlights: string[];
  created_at: string;
  thumbnail_url: string;
}

export async function getInfoFromStreamId(streamId: string): Promise<StreamInfo> {
  const headers = await createHeaders();
  log('Getting stream info', { streamId });

  const response = await fetch(`${baseUrl}/info_from_stream_id/${streamId}`, {
    method: 'GET',
    headers: headers,
  });

  if (!response.ok) {
    const error = `Request failed with status ${response.status}`;
    log('Get stream info error', error);
    throw new Error(error);
  }

  const data = await response.json();
  log('Stream info retrieved', { streamId });
  return data;
}

export async function updateHighlightFromHighlightId(highlightData: {
  highlight_id: string;
  stream_id: string;
  start_time: string;
  end_time: string;
  subtitles: string;
  fullscreen: boolean;
  text_color: string;
  censor_subtitles: boolean;
  facecam_coordinates: number[];
  gameplay_bounds: number[];
  url: string;
  font_name: string | null;
}) {
  const headers = await createHeaders();
  log('Updating highlight', highlightData);

  const response = await fetch(`${baseUrl}/update-highlights-from-highlight_id`, {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(highlightData),
  });

  if (!response.ok) {
    const error = `Request failed with status ${response.status}`;
    log('Update highlight error', error);
    throw new Error(error);
  }

  const data = await response.json();
  log('Highlight updated', data);
  return data;
}

export async function createClipFromHighlightId(highlightId: string) {
  const headers = await createHeaders();
  log('Creating clip from highlight ID', { highlightId });

  const response = await fetch(`${baseUrl}/create-clip-from-highlight_id`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({ highlight_id: highlightId }),
  });

  if (!response.ok) {
    const error = `Request failed with status ${response.status}`;
    log('Create clip error', error);
    throw new Error(error);
  }

  const data = await response.json();
  log('Clip creation initiated', { jobId: data });
  return data;
}

export async function getAllHighlights(): Promise<Highlight[]> {
  const headers = await createHeaders();
  const response = await fetch(`${baseUrl}/get-all-highlights/`, {
    method: 'GET',
    headers: headers,
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch highlights: ${response.statusText}`);
  }

  const data = await response.json();
  return data;
}

export async function getUserSubscriptionInfo() {
  const headers = await createHeaders();
  log('Getting user subscription info');

  const response = await fetch(`${baseUrl}/get-user-subscription-info`, {
    method: 'GET',
    headers: headers,
  });

  if (!response.ok) {
    const error = `Request failed with status ${response.status}`;
    log('Get user subscription info error', error);
    throw new Error(error);
  }

  const data = await response.json();
  log('User subscription info received', data);
  return data;
}
  
  export async function getHighlights(streamId: string): Promise<string[]> {
  const headers = await createHeaders();
  log('Getting highlights for stream', { streamId });

  const response = await fetch(`${baseUrl}/get-highlights/${streamId}`, {
    method: 'GET',
    headers: headers,
  });

  if (!response.ok) {
    const error = `Request failed with status ${response.status}`;
    log('Get highlights error', error);
    throw new Error(error);
  }

  const data = await response.json();
  log('Highlights retrieved', { streamId, highlightCount: data.length });
  return data;
}


export async function getStreams(cursor, limit = 8) {
  const headers = await createHeaders();
  log('Getting streams for user', { cursor, limit });

  const url = new URL(`${baseUrl}/get-recent-streams`);
  if (cursor) url.searchParams.append('cursor', cursor);
  url.searchParams.append('limit', limit.toString());

  const response = await fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  });

  if (!response.ok) {
    const error = `Request failed with status ${response.status}`;
    log('Get streams error', error);
    throw new Error(error);
  }

  const data = await response.json();
  log('Streams retrieved', { streamCount: data.items.length, hasNextPage: !!data.next_cursor });
  return data;
}

export async function getHighlightsWithUrls(streamId: string){
  const headers = await createHeaders();
  const response = await fetch(`${baseUrl}/get-highlights-with-urls/${streamId}`, {
    method: 'GET',
    headers: headers,
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}

export const findAndCreateHighlights = async( requestBody: {
  url: string;
  num_of_highlights: number;
  subtitles: string | null;
  fullscreen: boolean;
  text_color: string;
  censor_subtitles: boolean;
  facecam_coordinates: [number, number, number, number] | [] | null;
  gameplay_bounds: [number, number, number, number] | [] | null; 
  font_name: string | null;
}) => {
  console.log('Request body for findAndCreateHighlights:', requestBody);
  const headers = await createHeaders();
  const response = await fetch(`${baseUrl}/find_and_create_highlights`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
};
